import logo from "./images/logo.png";
import { useForm } from "react-hook-form";
import "./App.css";
import { Fragment, useState } from "react";

function App() {
  const [link, setLink] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    setLink(
      `https://www.darc.ai/?autologin_code=${data.token}${
        data.products !== "" ? "&atc=" + data.products : ""
      }`
    );
  };
  return (
    <div className="bg-gray-800 min-h-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center">
        <img className="w-80 mb-2" src={logo} alt="logo" />
        <p className="text-xl text-white font-medium">Payment link generator</p>
        <form className="mt-10 w-96" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <label className="text-sm text-white font-medium">
              Token login
            </label>
            <input
              className="block border border-gray-200 rounded px-2 py-1 leading-6 w-full focus:outline-none focus:border-nf-primary focus:ring-nf-primary focus:ring-1"
              {...register("token", { required: true })}
              placeholder="qaw2pY9uioA0i1glyUNNzM7Q9mZT5BAz"
            />
            {errors.token && (
              <span className="text-red-400">Το token ειναι υποχρεωτικό</span>
            )}
          </div>
          <div className="mt-2 flex flex-col">
            <label className="text-sm text-white font-medium">
              Redirect to product(s) divided by commas (ids)
            </label>
            <input
              className="block border border-gray-200 rounded px-2 py-1 leading-6 w-full focus:outline-none focus:border-nf-primary focus:ring-nf-primary focus:ring-1"
              {...register("products")}
              placeholder="8165"
            />
          </div>
          <button
            type="submit"
            className="mt-2 inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none w-full px-4 py-2 leading-6 rounded border-nf-primary bg-nf-primary text-white hover:bg-black"
          >
            Δημιουργία link
          </button>
        </form>
        {link !== "" && (
          <Fragment>
            <div className="mt-4 rounded p-6 bg-slate-50">{link}</div>
            <button
              className="text-green-200"
              onClick={() => {
                navigator.clipboard.writeText(link);
              }}
            >
              Copy me
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default App;
